export const componentConfig = {
  cid: 'orderlistPerformanceSmallChart',
  id: 'orderlistPerformanceSmallChart',
  disabled: null,
  dataSource: 'ORDERLIST.smallChartMetrics',
  breakpoints: ['lg3', 'sm12'],
  name: 'genericLineChart',
  title: '',
  __typename: 'genericLineChart',
  commonTweaks: ['datebackfill'],
  dataKey: 'Day',
  exportableTableData: 'xls',
  hideEmptySeriesKeys: null,
  hideIfNoData: null,
  hideLegend: true,
  availableLineSeriesKeys: ['Actual', 'Before'],
  lineSeriesKeys: ['Actual', 'Before'],
  availableMetrics: ['active', 'pending', 'endingSoon', 'expired'],
  tooltips: [
    {
      key: 'active',
      tooltip: 'Click to show only Orders that have started and are live.',
    },
    {
      key: 'pending',
      tooltip: 'Click to show only Orders that have not yet started. This feature is coming soon.',
    },
    {
      key: 'endingSoon',
      tooltip: 'Click to show only Orders that are ending within the next 7 days.',
    },
    {
      key: 'expired',
      tooltip: 'Click to show only Orders that have expired.',
    },
  ],
  overrides: null,
  hideTitle: null,
  noDataTooltips: ['no data'],
  dateRange: 'thismonth',
  fetchData: { method: 'getOrderInfo' },
};

export const componentHeight = '120px';
