
import Vue from 'vue';
import utils from '../../../../../../util';
import MetricPie from '../shared/metricPie.vue';
import MetricPacing from '../shared/metricPacing.vue';
import { formatDate, orderStatusMapping } from '../../../../../order/orderUtils';
import { C360Icon } from '@c360/ui';
import debounce from 'lodash.debounce';
import analytics from '../../../../../../mixins/analytics';
import CampaignFriendlyNameEdit from '../../../../campaignNav/campaignFriendlyNameEdit.vue';
export default Vue.extend({
  name: 'OrderListItem',
  components: {
    MetricPie,
    MetricPacing,
    C360Icon,
    CampaignFriendlyNameEdit,
  },
  props: ['order', 'componentConfig'],
  mixins: [analytics],
  methods: {
    selectOrder(tactic = null) {
      this.analyticTrack(
        this.trackValue.ORDERS_GO_TO_DETAILS,
        `Go to Order "${this.order.FriendlyName || this.order.OrderName}"`,
      );
      const queryParams = { id: this.$route.query.id, tab: 'ordersummary', orderId: this.order.OrderId };
      if (tactic && tactic !== 'Overall') {
        queryParams['product'] = tactic;
      }
      this.$router.push({ query: queryParams });
    },
    formatDate(date: string) {
      return formatDate(date);
    },
    listenToScroll: debounce(function (): void {
      const pacingRowRef = this.$refs.pacingRow;
      if (pacingRowRef.scrollLeft < 20) {
        this.showLeftScrollGradient = false;
      } else {
        this.showLeftScrollGradient = true;
      }
      if (Math.abs(pacingRowRef.scrollLeft + pacingRowRef.clientWidth - pacingRowRef.scrollWidth) < 20) {
        this.showRightScrollGradient = false;
      } else {
        this.showRightScrollGradient = true;
      }
    }, 200),
    scrollByClick(side: string) {
      const pacingRowRef = this.$refs.pacingRow;
      pacingRowRef.scroll({
        top: 0,
        left: side === 'left' ? pacingRowRef.scrollLeft - 200 : pacingRowRef.scrollLeft + 200,
        behavior: 'smooth',
      });
    },
    getTooltipText(tactic: string) {
      return tactic === 'Overall Pacing'
        ? 'Overall "Pacing" for Campaigns with impression goals regardless of Product'
        : '"Pacing" for this Product only for Campaigns with impression goals.';
    },
    toggleCampaignFriendlyNameEdit(state: boolean) {
      this.showCampaignNameEdit = state;
    },
    async updateOrderFriendlyName(friendlyName: string) {
      this.friendlyNameToDisplay = friendlyName;
    },
    async copyOriginalOrderName() {
      try {
        await navigator.clipboard.writeText(this.order.OrderName);
        this.orderNameCopied = true;
      } catch (e) {
        console.error('Failed to copy: ', e);
      }
    },
  },
  data(): {
    showRightScrollGradient: Boolean;
    showLeftScrollGradient: Boolean;
    showCampaignNameEdit: boolean;
    friendlyNameToDisplay: string;
    orderNameCopied: boolean;
  } {
    return {
      showRightScrollGradient: false,
      showLeftScrollGradient: false,
      showCampaignNameEdit: false,
      friendlyNameToDisplay: this.order.FriendlyName || this.order.OrderName,
      orderNameCopied: false,
    };
  },
  mounted(): void {
    const pacingRowRef = this.$refs.pacingRow;
    if (pacingRowRef.scrollWidth > pacingRowRef.clientWidth) {
      this.showRightScrollGradient = true;
      pacingRowRef.addEventListener('scroll', () => {
        this.listenToScroll();
      });
    }
  },
  beforeDestroy(): void {
    const pacingRowRef = this.$refs.pacingRow;
    pacingRowRef.removeEventListener('scroll', this.listenToScroll);
  },
  computed: {
    showStatus(): boolean {
      return Boolean(this.order.OrderStatus);
    },
    orderStatus(): any {
      return orderStatusMapping.find(obj => obj.code === this.order.OrderStatus);
    },
    isVirtualOrder(): boolean {
      return this.order.OrderId === '99999';
    },
    isEndingSoon(): boolean {
      return parseInt(this.order.TimeLeft.split(' ')[0]) <= 8;
    },
    filteredPacing(): object {
      const pacing = this.order?.Pacing;

      let filteredFormattedPacing = [];
      for (const [key, value] of Object.entries(pacing)) {
        if (!this.componentConfig?.showEmptyPacing) {
          if (value === '-') continue;
        }
        let formattedTacticName = key === 'Overall' ? 'Overall Pacing' : utils.tacticTitleMap(key);
        if (key === 'VIDEO') formattedTacticName = 'Digital Video';
        filteredFormattedPacing.push({
          rawTacticName: key,
          tactic: formattedTacticName,
          pacingValue: value === '-' ? 'N/A' : value,
        });
      }
      // overall pacing should always be the first one regardless of the value
      const overall = [];
      if (!['N/A', '-'].includes(pacing.Overall)) overall.push(filteredFormattedPacing.shift());
      filteredFormattedPacing = filteredFormattedPacing.sort((a, b) => {
        if (Math.abs(parseFloat(a.pacingValue)) > Math.abs(parseFloat(b.pacingValue))) return -1;
        return 1;
      });
      return [...overall, ...filteredFormattedPacing];
    },
  },
});
